@media print {
	* {
		background: transparent !important;
		color: #000 !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}

	a {
		text-decoration: underline;

		&:visited {
			text-decoration: underline;
		}
	}

	pre {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}

	tr {
		page-break-inside: avoid;
	}

	img {
		page-break-inside: avoid;
		max-width: 100% !important;
	}

	@page {
		margin: 0.5cm;
	}

	p {
		orphans: 3;
		widows: 3;
	}

	h2 {
		orphans: 3;
		widows: 3;
		page-break-after: avoid;
	}

	h3 {
		orphans: 3;
		widows: 3;
		page-break-after: avoid;
	}

	a[href]:after {
		content: "(" attr(href) ")";
	}

	abbr[title]:after {
		content: "(" attr(title) ")";
	}

	.ir a:after {
		content: "";
	}

	a[href^="javascript:"]:after {
		content: "";
	}

	a[href^="#"]:after {
		content: "";
	}

}
