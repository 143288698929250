#footer {
	margin-top: 40px;
	padding: 20px 0;
	background: #333F4D;
	color: #d7d9db;

	> .container {
		display: flex;
		justify-content: space-between;
	}

	a {
		color: inherit;
		&:hover {
			text-decoration: none;
			color: #FFF;
		}
	}
}

.footer-up {
	text-align: right;
}