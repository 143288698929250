.container {
	max-width: 960px;
	width: 100%;
	margin: 0 auto;
	padding: 0 10px;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.icon {
	display: inline-block;
	vertical-align: middle;
}

@include sprites(map-get($spritesheet, 'sprites'));