.input {

}

.textarea {
	
}

.form {
	.required:after {
		content: ' *';
	}
}

// Form validation
.error {
	border-color: red !important;
}

.valid {
	border-color: green !important;
}