.content {
	h2 {
		margin: 0 0 .5em;
	}

	td {
		padding: 15px;
	}

	table {
		width: 100%;
	}

	p {
		&:last-child {
			margin: 0;
		}
	}

	.block-tags {
		margin: 0 0 10px;
	}

	img {
	    width: auto;
	    max-width: 100%;
	}
}