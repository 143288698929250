.button {
	display: inline-block;
	border: 1px solid #C55142;
	font-weight: 700;
	font-size: 15px;
	text-decoration: none;
	padding: 6px 12px;
	border-radius: 3px;
	color: #FFF;

    background-image: -webkit-linear-gradient(#da5a47, #cc5643);
    background-image: -moz-linear-gradient(#da5a47, #cc5643);
    background-image: -ms-linear-gradient(#da5a47, #cc5643);
    background-image: -o-linear-gradient(#da5a47, #cc5643);
    background-image: linear-gradient(#da5a47, #cc5643);

	&:hover {
		color: #FFF;
		text-decoration: none;
		background-image: -webkit-linear-gradient(#bf503f, #b34b3b);
		background-image: -moz-linear-gradient(#bf503f, #b34b3b);
		background-image: -ms-linear-gradient(#bf503f, #b34b3b);
		background-image: -o-linear-gradient(#bf503f, #b34b3b);
		background-image: linear-gradient(#bf503f, #b34b3b);
	}
}

.button-link {

}