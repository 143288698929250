.owl-carousel {
	display: block;

	> * {
		display: none;

		&:first-child {
			display: block;
		}
	}

	.grabbing {
		cursor: grab;
	}
}
