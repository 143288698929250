html {
	box-sizing: border-box;
}

* {
	box-sizing: inherit;

	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

body {
	font: 400 16px/23px Lato, sans-serif;
	color: $font-color;
	background: $background-color;
	min-width: 280px;
}

img {
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	// margin: 0;
	line-height: 1;
}

p {
	margin-top: 0;
}

a {
	text-decoration: none;
	color: $link-color;

	&:hover {
		text-decoration: underline;
	}
}

hr {
	display: block;
	border-top: 1px solid #CCC;
	border-bottom: 0;
	margin: 1em 0;
}

blockquote {
	font-weight: bold;
	margin: 0;

	&:before {
		content: '“ ';
		color: $quote-color;
	}

	&:after {
		content: ' “';
		color: $quote-color;
	}
}

label {
	cursor: pointer;
}

small {
	font-size: 10px;
}
