/*
 * 	Core Owl Carousel CSS File
 *	v1.3.3
 */

/* display none until init */
.owl-carousel {
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;

	.owl-wrapper {
		display: none;
		position: relative;
		-webkit-transform: translate3d(0px, 0px, 0px);

		/* clearfix */
		&:after {
			content: ".";
			display: block;
			clear: both;
			visibility: hidden;
			line-height: 0;
			height: 0;
		}
	}

	.owl-wrapper-outer {
		overflow: hidden;
		position: relative;
		width: 100%;
	}

	.owl-wrapper-outer.autoHeight {
		-webkit-transition: height 500ms ease-in-out;
		-moz-transition: height 500ms ease-in-out;
		-ms-transition: height 500ms ease-in-out;
		-o-transition: height 500ms ease-in-out;
		transition: height 500ms ease-in-out;
	}

	.owl-item {
		float: left;
	}

	/* cursor */
	.grabbing {
		cursor: move;
	}

	/* fix */
	.owl-wrapper,
	.owl-item {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility:    hidden;
		-ms-backface-visibility:     hidden;
		-webkit-transform: translate3d(0,0,0);
		-moz-transform: translate3d(0,0,0);
		-ms-transform: translate3d(0,0,0);
	}
}

.owl-controls {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	.owl-page,
	.owl-buttons div {
		cursor: pointer;
	}
}
