#header {
	background: #FFF;

	> .container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	a {
		color: inherit;

		&:hover {
			color: lighten($font-color, 20%)
		}
	}
}

.nav-logo {
	text-decoration: none;
	font-size: 42px;
	line-height: 1;
	margin: 20px 0;
	font-weight: 700;

	&:hover {
		text-decoration: none;
	}
}

#nav {
	display: flex;
	justify-content: space-between;
	width: 200px;
}
