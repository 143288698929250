.block {
	background: #FFF;
	margin: 20px 0;
	border-radius: 4px;
	overflow: hidden;

	.button {
		margin-top: 10px;
	}
}

.block-spacing {
	padding: 20px;
}

.block-center {
	text-align: center;
}

.block-title {
	margin: 0 0 10px;
}

.block-subtitle {
	color: $gray;
	font-size: 14px;
}

.block-sideimage {
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: 10px 0 0;
    margin-right: 10px;
    margin-left: -10px;

    min-height: 270px;
}

.block-project {
	min-height: 270px;
	display: flex;

	> * {
		flex: 1;
	}

	.block-spacing {
		padding: 30px;
	}
}

.block-tag {
	display: inline-block;
	border: 1px solid $border-color;
	border-radius: 4px;
	margin: 5px 0;
	margin-right: 5px;
	padding: 2px 5px;
	background: #FAFAFA;

	&:hover {
		background: darken(#FAFAFA, 2%);		
	}
}

.block-content {
	background: #FFF;
}

.block-image-main {
	width: 100%;
} 

.block-content--padding {
	padding: 20px;
}